<template>
  <div>
    <b-container class="bv-example-row" fluid>
      <!-- Stack the columns on mobile by making one full-width and the other half-width -->
      <b-form-row>
        <b-col>
          <div class="container-selector">
            <b-list-group horizontal="sm">
              <b-list-group-item
                class="selector"
                v-for="country in rbacOptions"
                :key="country.name"
                v-bind:class="{ active: isActive(country.name) }"
                @click="setActive(country.name)"
              >
                {{ country.name }}
                <div class="sub" v-if="country.sub">{{ country.sub }}</div>
                <img alt="Flag" class="flag" :src="getImgUrl(country.name)" />
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
      </b-form-row>
      <component v-bind:is="selectedCountryComponent"></component>
    </b-container>
  </div>
</template>

<script>
// import AustriaView from "@/views/CountryViews/Austria.vue";
// import BelgiumView from "@/views/CountryViews/Belgium.vue";
import CroatiaView from "@/views/CountryViews/Croatia.vue";
import CzechiaView from "@/views/CountryViews/Czechia.vue";
import FranceView from "@/views/CountryViews/France.vue";
import GermanyView from "@/views/CountryViews/Germany.vue";
import HungaryView from "@/views/CountryViews/Hungary.vue";
import ItalyView from "@/views/CountryViews/Italy.vue";
import NetherlandsView from "@/views/CountryViews/Netherlands.vue";
import PolandView from "@/views/CountryViews/Poland.vue";
import PortugalView from "@/views/CountryViews/Portugal.vue";
import RomaniaView from "@/views/CountryViews/Romania.vue";
import SlovakiaView from "@/views/CountryViews/Slovakia.vue";
import SpainView from "@/views/CountryViews/Spain.vue";
import TurkeyView from "@/views/CountryViews/Turkey.vue";
import UkraineView from "@/views/CountryViews/Ukraine.vue";

import Vue from "vue";

export default {
  name: "country-view",
  components: {
    // AustriaView,
    // BelgiumView,
    CroatiaView,
    CzechiaView,
    FranceView,
    GermanyView,
    HungaryView,
    ItalyView,
    NetherlandsView,
    PolandView,
    PortugalView,
    RomaniaView,
    SlovakiaView,
    SpainView,
    TurkeyView,
    UkraineView,
  },
  data() {
    if (process.env.VUE_APP_ROUTER_HOST === "dev") {
      return {
        countryOptions: [
          // { name: "Austria", key: "at", sub: null },
          // { name: "Belgium", key: "be", sub: null },
          { name: "Croatia", key: "hr", sub: null },
          { name: "Czechia", key: "cz", sub: null },
          { name: "France", key: "fr", sub: null },
          { name: "Netherlands", key: "nl", sub: null },
          { name: "Germany", key: "de", sub: null },
          { name: "Hungary", key: "hu", sub: null },
          { name: "Italy", key: "it", sub: null },
          { name: "Poland", key: "pl", sub: null },
          { name: "Portugal", key: "pt", sub: null },
          { name: "Romania", key: "ro", sub: null },
          { name: "Slovakia", key: "sk", sub: null },
          { name: "Spain", key: "es", sub: null },
          { name: "Turkey", key: "tr", sub: "IN TESTING" },
          { name: "Ukraine", key: "ua", sub: "IN TESTING" },
        ],
        selectedCountry: "",
      };
    }

    return {
      countryOptions: [
        // { name: "Austria", key: "at", sub: null },
        // { name: "Belgium", key: "be", sub: null },
        { name: "Croatia", key: "hr", sub: null },
        { name: "Czechia", key: "cz", sub: null },
        { name: "France", key: "fr", sub: null },
        { name: "Netherlands", key: "nl", sub: null },
        { name: "Germany", key: "de", sub: null },
        { name: "Hungary", key: "hu", sub: null },
        { name: "Italy", key: "it", sub: null },
        { name: "Poland", key: "pl", sub: null },
        { name: "Portugal", key: "pt", sub: null },
        { name: "Romania", key: "ro", sub: null },
        { name: "Slovakia", key: "sk", sub: null },
        { name: "Spain", key: "es", sub: null },
        // { name: "Turkey", key: "tr", sub: "IN TESTING" },
        // { name: "Ukraine", key: "ua", sub: "IN TESTING" }
      ],
      selectedCountry: "",
    };
  },
  mounted() {
    this.selectedCountry = this.rbacOptions[0].name;
  },
  computed: {
    rbacOptions() {
      const authorised = [];
      const countries = this.countryOptions;
      const tokenRoles = Vue.$keycloak.tokenParsed.realm_access.roles;
      let countryRoles = tokenRoles.filter((item) => item.includes("country"));
      const all = [];
      for (const role of countryRoles) {
        if (role == "country_all") {
          all.push(role);
          break;
        }
      }
      if (all.length) {
        countryRoles = all;
      }
      for (const country of countries) {
        for (const role of countryRoles) {
          const spl = role.split("_");
          if (spl[1] == country.key || spl[1] == "all") {
            authorised.push(country);
          }
        }
      }
      return authorised;
    },
    selectedCountryComponent: function () {
      return this.selectedCountry + "View";
    },
  },
  methods: {
    getImgUrl(country) {
      var images = require.context("@/assets/flags", false, /\.png$/);
      return images(`./${country}.png`);
    },
    isActive(key) {
      return this.selectedCountry == key;
    },
    setActive(key) {
      this.selectedCountry = key;

      document.dispatchEvent(
        new CustomEvent("adobeDataLayerEvent", {
          detail: {
            event: "button_click",
            eventValue: "CountryView.selector=" + key,
          },
        })
      );
    },
  },
};
</script>

<style>
.list-group {
  border-radius: 0.75rem;
}
.list-group-item {
  background-color: #f9f9f9;
}
.flag {
  height: 35px;
  margin: 2px 2px 2px 5px;
  border: solid #757575 1px;
}
.container-selector {
  margin: 5px 0px 2px;
}
.selector {
  font-size: 1.25rem;
  border: solid 1px rgba(0, 0, 0, 0.3);
}
.selector:hover {
  background-color: #001d3d;
  color: white;
  cursor: pointer;
}
.selector.active {
  background-color: #002146;
  color: white;
  border: solid 1px rgba(0, 0, 0, 0.3);
}
.sub {
  font-size: 0.5rem;
  position: absolute;
}
</style>
