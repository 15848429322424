<template>
  <div>
    <div v-for="(contentText, index) in displayText" :key="index">
      <b-form-row>
        <b-col cols="12">
          <div
            class="box-container flex; vertical-align: middle; border: solid; border-width: 0.1em; border-color: black;"
          >
            <div style="padding: 0.1em 2em">
              <div
                style="
                  padding: 1em;
                  text-align: left;
                  color: black;
                  font-weight: bold;
                "
              >
                {{ contentText }}
              </div>
            </div>
          </div>
        </b-col>
      </b-form-row>
    </div>
    <!-- Top-level row -->
    <b-form-row>
      <!-- Plots -->
      <b-col cols="12" sm="6" lg="3">
        <b-row>
          <b-col cols="6" style="padding-right: 2px">
            <InfoBox
              footer="Number of Tools Deployed"
              :description="`Total number of DISH tools deployed in ${country}`"
            >
              <h2>{{ nTools }}</h2>
            </InfoBox>
          </b-col>
          <b-col cols="6" style="padding-left: 2px">
            <InfoBox
              footer="Total Active DISH Customers"
              description="Total number of active digital METRO customers. Only active HoReCa METRO customers who are also active DISH customers are taken into account."
            >
              <h2>{{ nActive }}</h2>
            </InfoBox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="box-container flex">
              <BarChart
                title="Number of DISH customers"
                :series="cumulativeDigitalCustomers"
                :categories="digitalCustomerCategories"
                :height="124"
                :shortAxisLabels="true"
                :locale="locale"
                description="Development of cumulative digital METRO customer numbers in the last 12 months. Only active HoReCa METRO customers who are also DISH customers are taken into account."
                subheader="Until end of last month"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <!-- Plots -->
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <HorizontalBarChart
            title="Total customers per bundle"
            :series="totalToolCustomers"
            :categories="totalToolCategories"
            :distributed="false"
            :locale="locale"
            :enableDataLabels="true"
            description="Total number of (active) digital METRO customers per bundle. Only active HoReCa METRO customers who are also (active) DISH customers are taken into account."
            subheader="Until end of last month"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <BarChart
            :title="`Total bundle revenue (DISH) [${
              this.currencyMapper[this.locale]
            }]`"
            :series="totalToolRevenue"
            :categories="toolRevenueCategories"
            :isCurrency="true"
            :locale="locale"
            description="Attention: These numbers are PRELIMINARY. The final conditions are not yet clarified and numbers might change. KPI description: Net DISH bundle revenue in the last 12 months (all reseller). Note: Reserv. = Professional Reservation; Order = Professional Order"
            subheader="Until end of last month"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <HorizontalBarChart
            title="Churn comparison [%]"
            :series="churnValue"
            :categories="churnCategories"
            :isPercentage="true"
            :locale="locale"
            :enableDataLabels="true"
            description="Percentage customer outflow rates for digital vs. non-digital METRO customers - customers that have not made a purchase in the last 12 months are considered as churned. Limited to METRO's HoReCa customers created more than 12 months ago. Additionaly, digital customers were active DISH customers one year ago."
            subheader="Until end of last month"
          />
        </div>
      </b-col>

      <!-- Second Row -->
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <div>
            <b-form-row>
              <b-col>
                <div style="min-height: 100px">
                  <AreaChart
                    :title="`Average basket value [${
                      this.currencyMapper[this.locale]
                    }]`"
                    :series="averageSalesValue"
                    :categories="averageSalesCategories"
                    :isCurrency="true"
                    :locale="locale"
                    :height="100"
                    :slimline="true"
                    description="Average monthly basket value for digital/non-digital METRO customers for last 12 months. Limited to active HoReCa (and active DISH) customers being METRO customers (and digitized) for more than one year."
                    subheader="Until end of last month, monthly average"
                  />
                </div>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <div style="min-height: 100px; margin-top: -15px">
                  <HorizontalBarChart
                    title="Average purchasing frequency"
                    :series="purchasingFrequency"
                    :categories="purchasingCategories"
                    :height="85"
                    :enableDataLabels="true"
                    description="Average number of days of purchases for digital/non-digital METRO customers in last 12 months. It is the cumulative amount of monthly averages. Limited to active HoReCa (and active DISH) customers being METRO customers (and digitized) for more than one year."
                    subheader="Until end of last month, yearly average"
                  />
                </div>
              </b-col>
            </b-form-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <AreaChart
            :title="`Average cumulative sales value [${
              this.currencyMapper[this.locale]
            }]`"
            :series="cumulativeValue"
            :categories="cumulativeValueCategories"
            :isCurrency="true"
            :locale="locale"
            description="Average basket value x average number of visits for digital/non-digital METRO customers. The figure displays the cumulative development in the last 12 months. Limited to active HoReCa (and active DISH) customers being METRO customers (and digitized) for more than one year."
            subheader="Until end of last month, cumulative monthly average"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <BarChart
            title="Incremental Sales Uplift [%]"
            :series="salesUplift"
            :categories="salesUpliftCategories"
            :isPercentage="true"
            :shortAxisLabels="false"
            :locale="locale"
            description="Monthly sales uplift per METRO customer: Incremental METRO sales value for new DISH customers (being in 12 months period after moment of digital conversion) vs. non-digital customers for the last 12 months. Limited to active DISH and active HoReCa customers being METRO customers for more than two years."
            subheader="Until end of last month, monthly level"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <Gauge
            title="Own brand share [%]"
            :series="obProportion"
            :categories="obCategories"
            description="Percentage of METRO revenue generated via purchases of METRO own brands for digital/non-digital customers, based on last 12 months. Limited to active HoReCa (and active DISH) customers being METRO customers (and digitized) for more than one year."
            subheader="Until end of last month"
          />
        </div>
      </b-col>
    </b-form-row>
    <!-- Financial Year View -->
    <b-form-row>
      <b-col>
        <div class="separator-box">
          <div class="separator-main">
            Actual vs. Target per Product | Financial Year Perspective
          </div>
        </div>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container flex">
          <div style="min-height: 150px">
            <LineChart
              title="DISH Starter"
              :series="dishStartAcqs"
              :categories="dishStartAcqsCategories"
              description="Actual (gross) and planned acquisition figures for tool. Only customers acquired for METRO are taken into account."
              subheader="# of new customers (cum.) | Current FY"
              :locale="locale"
            />
          </div>

          <div class="target-container">
            <b-row>
              <b-col cols="6" style="padding-right: 2px">
                <InfoBox footer="Actual vs Target YTD">
                  <h2>{{ dishStartAcqsYTD }}</h2>
                </InfoBox>
              </b-col>
              <b-col cols="6" style="padding-left: 2px">
                <InfoBox footer="Actual vs Target FY">
                  <h2>{{ dishStartAcqsFY }}</h2>
                </InfoBox>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col cols="12" sm="6" lg="3">
        <div class="box-container flex">
          <div style="min-height: 150px">
            <LineChart
              title="DISH Professional Reservation"
              :series="dishResAcqs"
              :categories="dishResAcqsCategories"
              description="Actual (gross) and planned acquisition figures for tool. Only customers acquired for METRO are taken into account."
              subheader="# of new customers (cum.) | Current FY"
              :locale="locale"
            />
          </div>

          <div class="target-container">
            <b-row>
              <b-col cols="6" style="padding-right: 2px">
                <InfoBox footer="Actual vs Target YTD">
                  <h2>{{ dishResAcqsYTD }}</h2>
                </InfoBox>
              </b-col>
              <b-col cols="6" style="padding-left: 2px">
                <InfoBox footer="Actual vs Target FY">
                  <h2>{{ dishResAcqsFY }}</h2>
                </InfoBox>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col cols="12" sm="6" lg="3">
        <div class="box-container flex">
          <div style="min-height: 150px">
            <LineChart
              title="DISH Professional Order"
              :series="dishOrdAcqs"
              :categories="dishOrdAcqsCategories"
              description="Actual (gross) and planned acquisition figures for tool. Only customers acquired for METRO are taken into account."
              subheader="# of new customers (cum.) | Current FY"
              :locale="locale"
            />
          </div>

          <div class="target-container">
            <b-row>
              <b-col cols="6" style="padding-right: 2px">
                <InfoBox footer="Actual vs Target YTD">
                  <h2>{{ dishOrdAcqsYTD }}</h2>
                </InfoBox>
              </b-col>
              <b-col cols="6" style="padding-left: 2px">
                <InfoBox footer="Actual vs Target FY">
                  <h2>{{ dishOrdAcqsFY }}</h2>
                </InfoBox>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col cols="12" sm="6" lg="3">
        <div class="box-container flex">
          <div style="min-height: 150px">
            <LineChart
              title="DISH Premium"
              :series="dishPremAcqs"
              :categories="dishPremAcqsCategories"
              description="Actual (gross) and planned acquisition figures for tool. Only customers acquired for METRO are taken into account."
              subheader="# of new customers (cum.) | Current FY"
              :locale="locale"
            />
          </div>
          <div class="target-container">
            <b-row>
              <b-col cols="6" style="padding-right: 2px">
                <InfoBox footer="Actual vs Target YTD">
                  <h2>{{ dishPremAcqsYTD }}</h2>
                </InfoBox>
              </b-col>
              <b-col cols="6" style="padding-left: 2px">
                <InfoBox footer="Actual vs Target FY">
                  <h2>{{ dishPremAcqsFY }}</h2>
                </InfoBox>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import InfoBox from "@/components/InfoBox.vue";
import Gauge from "@/components/Plots/Gauge.vue";
import BarChart from "@/components/Plots/BarChart.vue";
import HorizontalBarChart from "@/components/Plots/HorizontalBarChart.vue";
import AreaChart from "@/components/Plots/AreaChart.vue";
import LineChart from "@/components/Plots/LineChart.vue";
import formatValue from "@/components/Plots/formatValue";

export default {
  name: "template-country-view",
  props: { country: { type: String }, locale: { type: String } },
  components: {
    InfoBox,
    Gauge,
    BarChart,
    HorizontalBarChart,
    AreaChart,
    LineChart,
  },
  data() {
    return {
      //
      currencyMapper: {
        at: "€",
        be: "€",
        hr: "kn",
        cz: "Kč",
        fr: "€",
        nl: "€",
        de: "€",
        hu: "Ft",
        it: "€",
        pl: "zł",
        pt: "€",
        ro: "L",
        sk: "€",
        es: "€",
        tr: "₺",
        ua: "₴",
      },
      displayText: [],
      nActive: "-",
      nTools: "-",
      //
      digitalCustomerCategories: [],
      cumulativeDigitalCustomers: [],
      //
      totalToolCategories: [],
      totalToolCustomers: [],
      //
      toolRevenueCategories: [],
      totalToolRevenue: [],
      //
      averageSalesCategories: [],
      averageSalesValue: [],
      //
      purchasingFrequency: [],
      purchasingCategories: [],
      //
      cumulativeValue: [],
      cumulativeValueCategories: [],
      //
      salesUpliftCategories: [],
      salesUplift: [],
      //
      churnCategories: [],
      churnValue: [],
      //
      obProportion: [],
      obCategories: [],
      //
      dishStartAcqs: [],
      dishStartAcqsCategories: [],
      dishStartAcqsYTD: "-",
      dishStartAcqsFY: "-",
      //
      dishResAcqs: [],
      dishResAcqsCategories: [],
      dishResAcqsYTD: "-",
      dishResAcqsFY: "-",
      //
      dishOrdAcqs: [],
      dishOrdAcqsCategories: [],
      dishOrdAcqsYTD: "-",
      dishOrdAcqsFY: "-",
      //
      dishPremAcqs: [],
      dishPremAcqsCategories: [],
      dishPremAcqsYTD: "-",
      dishPremAcqsFY: "-",
      //

      timer: "",
    };
  },
  methods: {
    fetchData() {
      this.fetchMessages();
      this.fetchDigitalCustomers();
      this.fetchTotalToolCustomers();
      this.fetchDigitalActive();
      this.fetchDeployedTools();
      this.fetchToolRevenue();
      this.fetchSalesValue();
      this.fetchCumulativeValue();
      this.fetchSalesUplift();
      this.fetchChurn();
      this.fetchObProportion();
      this.fetchAcquisition();
      // document.parsedKcToken = this.$keycloakToken;
    },
    fetchMessages() {
      this.$http
        .get(`/api/v1/information/messages`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          this.displayText = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchDigitalCustomers() {
      this.$http
        .get(`/api/v1/customers/digital`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          this.digitalCustomerCategories = response.data.categories;
          this.cumulativeDigitalCustomers = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchTotalToolCustomers() {
      this.$http
        .get(`/api/v1/customers/total`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          this.totalToolCategories = response.data.categories;
          this.totalToolCustomers = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchDigitalActive() {
      this.$http
        .get(`/api/v1/customers/activedigital`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          this.nActive = response.data.value;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchToolRevenue() {
      this.$http
        .get(`/api/v1/tools/revenue`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          this.toolRevenueCategories = response.data.categories;
          this.totalToolRevenue = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchDeployedTools() {
      this.$http
        .get(`/api/v1/tools/deployed`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          this.nTools = response.data.value;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchSalesValue() {
      this.$http
        .get(`/api/v1/transactions/sales`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          this.averageSalesValue = response.data.basket.data;
          this.averageSalesCategories = response.data.basket.categories;

          this.purchasingFrequency = response.data.visits.data;
          this.purchasingCategories = response.data.visits.categories;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchCumulativeValue() {
      this.$http
        .get(`/api/v1/transactions/cumulativevalue`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          this.cumulativeValue = response.data.data;
          this.cumulativeValueCategories = response.data.categories;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    //Removed the Crotia from the exlude Countries list on 29-Mar-2023
    fetchSalesUplift() {
      const excludedCountries = [
        "Austria",
        "Belgium",
        "Slovakia",
        "Netherlands",
      ];
      const excludedOnEnv = ["prod", "acc", "stg"];
      if (
        excludedOnEnv.includes(process.env.VUE_APP_ROUTER_HOST) &&
        excludedCountries.includes(this.country)
      ) {
        this.salesUplift = {};
        this.salesUpliftCategories = {};
      } else if (process.env.VUE_APP_ROUTER_HOST === "dev") {
        this.$http
          .get(`/api/v1/transactions/uplift_psm_dev`, {
            params: {
              country: this.country,
            },
          })
          .then((response) => {
            this.salesUplift = response.data.percentage;
            this.salesUpliftCategories = response.data.categories;
          })
          .catch(() => {
            //gracefully catch unsuccessful fetch
          });
      } else {
        this.$http
          .get(`/api/v1/transactions/uplift_psm_prod`, {
            params: {
              country: this.country,
            },
          })
          .then((response) => {
            this.salesUplift = response.data.percentage;
            this.salesUpliftCategories = response.data.categories;
          })
          .catch(() => {
            //gracefully catch unsuccessful fetch
          });
      }
    },
    fetchChurn() {
      this.$http
        .get(`/api/v1/customers/churn`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          this.churnCategories = response.data.categories;
          this.churnValue = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchObProportion() {
      this.$http
        .get(`/api/v1/transactions/ownbrand`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          // JSON responses are automatically parsed.
          this.obCategories = response.data.categories;
          this.obProportion = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchAcquisition() {
      this.$http
        .get(`/api/v1/tools/acquisitions`, {
          params: {
            country: this.country,
          },
        })
        .then((response) => {
          //
          this.dishStartAcqs = response.data.dishStart.plot.data;
          this.dishStartAcqsCategories =
            response.data.dishStart.plot.categories;
          this.dishStartAcqsYTD = formatValue(
            response.data.dishStart.kpi.ytd,
            this.locale,
            false,
            true
          );
          this.dishStartAcqsFY = formatValue(
            response.data.dishStart.kpi.fy,
            this.locale,
            false,
            true
          );
          //
          this.dishResAcqs = response.data.dishRes.plot.data;
          this.dishResAcqsCategories = response.data.dishRes.plot.categories;
          this.dishResAcqsYTD = formatValue(
            response.data.dishRes.kpi.ytd,
            this.locale,
            false,
            true
          );
          this.dishResAcqsFY = formatValue(
            response.data.dishRes.kpi.fy,
            this.locale,
            false,
            true
          );
          //
          this.dishOrdAcqs = response.data.dishOrd.plot.data;
          this.dishOrdAcqsCategories = response.data.dishOrd.plot.categories;
          this.dishOrdAcqsYTD = formatValue(
            response.data.dishOrd.kpi.ytd,
            this.locale,
            false,
            true
          );
          this.dishOrdAcqsFY = formatValue(
            response.data.dishOrd.kpi.fy,
            this.locale,
            false,
            true
          );
          this.dishPremAcqs = response.data.dishPrem.plot.data;
          this.dishPremAcqsCategories = response.data.dishPrem.plot.categories;
          this.dishPremAcqsYTD = formatValue(
            response.data.dishPrem.kpi.ytd,
            this.locale,
            false,
            true
          );
          this.dishPremAcqsFY = formatValue(
            response.data.dishPrem.kpi.fy,
            this.locale,
            false,
            true
          );
          // !!!!!!!!!!!!!
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    submitPageChange() {
      document.dispatchEvent(
        new CustomEvent("adobeDataLayerEvent", {
          detail: {
            event: "content_view_change",
            page: {
              pageName:
                "EN:XX:VALUE CREATION DASHBOARD: " +
                document.URL +
                " -> " +
                this.locale,
              productSection: "private",
              productType: "VALUE CREATION DASHBOARD",
              userLanguage: "EN",
              usercountry: "XX",
              userType: this.$vueRole,
              userID: this.$kcUserId,
            },
          },
        })
      );
    },
  },

  created() {
    this.fetchData();
    this.timer = setInterval(this.fetchData, 7200000);
    this.submitPageChange();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
h2 {
  font-size: 36px;
  margin-bottom: 5px;
}

.separator-box {
  padding: 10px;
}
.separator-main {
  font-size: 25px;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 5px;
  text-shadow: 1px 1px 1px #bbb;
  padding: 5px;
  border-bottom: 1px double black;
}

.target-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-top: -10px;
}
</style>
