<template>
  <div>
    <Template country="Czechia" locale="cz" />
  </div>
</template>

<script>
import Template from "@/views/CountryViews/Template.vue";

export default {
  name: "czechia-view",
  components: {
    Template,
  },
};
</script>
