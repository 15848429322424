<template>
  <div v-if="isLoading" class="plot-container text-center">
    <b-spinner label="Spinning" class="spinner"></b-spinner>
  </div>
  <div v-else>
    <div class="h2 mb-0">
      <div class="plot-title">
        {{ title }}
        <b-icon v-if="hasDescription" icon="info-circle" ref="info"></b-icon>
        <b-tooltip v-if="hasDescription" :target="() => $refs['info']">
          <div class="tooltip-internal">
            {{ description }}
          </div>
        </b-tooltip>
      </div>
      <div class="plot-subheader">{{ subheader }}</div>
    </div>
    <apexchart
      type="line"
      :height="plotHeight"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import formatValue from "@/components/Plots/formatValue";
import colorOptions from "@/components/Plots/colorOptions.js";
import plotSettings from "@/components/Plots/plotSettings";

export default {
  name: "ApexLine",
  props: {
    title: String,
    series: Array,
    categories: Array,
    chartColors: { type: Array, default: colorOptions[0] },
    height: { type: Number, default: 215 },
    description: { type: String, default: "" },
    subheader: { type: String, default: "" },
    isCurrency: { type: Boolean, default: false },
    locale: { type: String },
  },
  computed: {
    plotHeight: function () {
      if (this.subheader === "") {
        return this.height + 12;
      } else {
        return this.height;
      }
    },
    isLoading: function () {
      return this.series.length === 0;
    },
    hasDescription: function () {
      return this.description != "";
    },
    chartOptions: function () {
      return {
        colors: this.chartColors,
        title: plotSettings.title,
        chart: {
          fontFamily: plotSettings.fontFamily,
          zoom: plotSettings.zoom,
          toolbar: plotSettings.toolbar,
          animations: plotSettings.animationsSimple,
        },
        dropShadow: plotSettings.dropShadow,
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "40%",
          },
        },
        markers: {
          size: 3,
          strokeWidth: 0,
        },
        xaxis: {
          categories: this.categories,
          type: "datetime",
          labels: {
            style: {
              colors: "black",
            },
            offsetY: -3,
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: true,
            color: "black",
          },
        },
        yaxis: {
          forceNiceScale: true,
          axisBorder: {
            show: true,
            color: "black",
            offsetX: -3,
          },
          labels: {
            offsetX: -10,
            style: {
              colors: "black",
              fontWeight: 900,
            },
            formatter: (value) => {
              return formatValue(value, this.locale, this.isCurrency);
            },
          },
        },
        stroke: {
          curve: "straight",
          width: 3,
        },
        tooltip: {
          x: {
            format: "MMM yyyy",
          },
        },
        grid: {
          show: true,
          strokeDashArray: 0,
          padding: {
            left: 0,
            top: -20,
            bottom: -5,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      };
    },
  },
};
</script>
