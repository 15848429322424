import Vue from "vue";
import VueRouter from "vue-router";

// views
import SplashView from "@/views/SplashView.vue";
import ValueView from "@/views/ValueView.vue";
import OverviewView from "@/views/OverviewView.vue";
import CountryView from "@/views/CountryView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Home",
    path: "/",
    components: {
      default: SplashView,
    },
    meta: {
      isAuthenticated: false,
    },
  },
  {
    name: "Value Creation",
    path: "/value",
    components: {
      default: ValueView,
    },
    meta: {
      isAuthenticated: true,
    },
  },
  {
    name: "Global Overview",
    path: "/overview",
    components: {
      default: OverviewView,
    },
    meta: {
      isAuthenticated: true,
    },
  },
  {
    name: "Country View",
    path: "/country",
    components: {
      default: CountryView,
    },
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

//Vue.$keycloak.tokenParsed.realm_access.roles fails if no token has been parsed yet, this is to prevent that from breaking the site
function getRoles() {
  try {
    return Vue.$keycloak.tokenParsed.realm_access.roles;
  } catch {
    return null;
  }
}

// To Do: further develop to handle different roles
function checkRoles(tokenRoles) {
  if (tokenRoles) {
    for (var role in tokenRoles) {
      if (!Vue.$keycloak.hasRealmRole(tokenRoles[role])) {
        return false;
      }
    }
    return true;
  } else {
    return null;
  }
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//Runs before every page access to check if user has logged in to keycloak SSO, and if so determines right to access page
router.beforeEach((to, from, next) => {
  Vue.prototype.$keycloakToken = Vue.$keycloak.tokenParsed;
  if (to.meta.isAuthenticated) {
    const basePath = window.location.toString();
    Vue.$roles = getRoles();
    if (!Vue.$keycloak.authenticated) {
      Vue.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path });
    } else if (checkRoles(Vue.$roles)) {
      // if ((to.name == "Global Overview" || to.name == "Value Creation") ) { // && !Vue.$roles.includes("country_all")) {
      if (
        to.name == "Value Creation" ||
        (to.name == "Global Overview" && !Vue.$roles.includes("country_all"))
      ) {
        next({ name: "Home" });
      } else {
        Vue.$keycloak
          .updateToken(70)
          .then(() => {
            next();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
  if (Vue.$keycloak.idToken) {
    if (
      Vue.$keycloak.tokenParsed.resource_access.VCDB.roles.includes(
        "country_all_dev"
      )
    ) {
      Vue.prototype.$vueRole = "admin";
    } else {
      Vue.prototype.$vueRole = "user";
    }
    Vue.prototype.$kcUserId = Buffer.from(
      Vue.$keycloak.tokenParsed.preferred_username,
      "binary"
    ).toString("base64");
    Vue.prototype.$changeToPage = to;
  }
});

export default router;
