<template>
  <div class="card-container">
    <b-container class="bv-example-row" fluid>
      <!-- Title -->
      <b-form-row>
        <b-col>
          <div class="title-box">
            <div class="title-main">Hospitality Digital Value Creation</div>
            <div class="title-footer">In absolute numbers</div>
          </div>
        </b-col>
      </b-form-row>

      <!-- Boxes -->
      <b-form-row>
        <b-col cols="12" sm="6" lg="4">
          <ValueBox>
            <div slot="main" class="value">{{ addedSales }}</div>
            <div slot="footer">
              <div class="subheader">Total sales delta</div>
              <div class="subsubheader">
                Digital customers (Up to 31/12/2020)
              </div>
            </div>
          </ValueBox>
        </b-col>

        <b-col cols="12" sm="6" lg="4">
          <ValueBox>
            <div slot="main" class="value">{{ salesUpliftAbsolute }}</div>
            <div slot="footer">
              <div class="subheader">Total digital sales uplift</div>
              <div class="subsubheader">
                Digital customers (Up to 31/12/2020)
              </div>
            </div>
          </ValueBox>
        </b-col>

        <b-col cols="12" sm="6" lg="4">
          <ValueBox>
            <div slot="main" class="value">{{ protectedRevenue }}</div>
            <div slot="footer">
              <div class="subheader">Protected sales from churn reduction</div>
              <div class="subsubheader">
                Digital customers (Up to 31/12/2020)
              </div>
            </div>
          </ValueBox>
        </b-col>
      </b-form-row>

      <!-- Plots -->
      <b-form-row>
        <b-col cols="12" sm="6" lg="4">
          <div class="box-container fixed">
            <AreaChart
              title="Cumulative customer value [€]"
              :series="cumulativeValue"
              :categories="cumulativeValueCategories"
              :isCurrency="true"
              :locale="locale"
              :height="180"
              description="Average basket value x Average visits for Digital/Non-Digital customers"
              subheader="Up to 31/12/2020"
            />
          </div>
        </b-col>
        <b-col cols="12" sm="6" lg="4">
          <div class="box-container fixed">
            <BarChart
              title="Digital sales uplift [€]"
              :series="salesUplift"
              :categories="salesUpliftCategories"
              :isCurrency="true"
              :locale="locale"
              :enableDataLabels="false"
              :shortAxisLabels="true"
              :height="180"
              description="Average monthly sales uplift per customer"
              subheader="Up to 31/12/2020, Monthly Average"
            />
          </div>
        </b-col>

        <b-col cols="12" sm="6" lg="4">
          <div class="box-container fixed">
            <HorizontalBarChart
              title="Churn comparison [%]"
              :series="churnValue"
              :categories="churnCategories"
              :locale="locale"
              :isPercentage="true"
              :enableDataLabels="true"
              description="Percentage customer outflow rates for digital vs. non-digital customers - customers that have not made a purchase in the last 12 months."
              subheader="Up to 31/12/2020, Monthly Average"
              :height="180"
            />
          </div>
        </b-col>
      </b-form-row>
    </b-container>
  </div>
</template>

<script>
import formatValue from "@/components/Plots/formatValue";

import ValueBox from "@/components/ValueBox.vue";
import BarChart from "@/components/Plots/BarChart.vue";
import HorizontalBarChart from "@/components/Plots/HorizontalBarChart.vue";
import AreaChart from "@/components/Plots/AreaChart.vue";

export default {
  name: "splash-view",
  components: {
    ValueBox,
    BarChart,
    HorizontalBarChart,
    AreaChart,
  },
  data() {
    return {
      locale: "de",
      addedSales: "- €",
      salesUpliftAbsolute: "- €",
      protectedRevenue: "- €",
      //
      cumulativeValue: [],
      cumulativeValueCategories: [],
      //
      salesUplift: [],
      salesUpliftCategories: [],
      //
      churnValue: [],
      churnCategories: [],
      timer: "",
    };
  },
  methods: {
    fetchData() {
      this.fetchAddedSales();
      this.fetchCumulativeValue();
      this.fetchSalesUplift();
      this.fetchProtectedRevenue();
      this.fetchChurn();
    },
    fetchAddedSales() {
      this.$http
        .get(`/api/v1/transactions/delta`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          // total sales delta
          var addedSales = response.data.value;
          this.addedSales = formatValue(addedSales, this.locale, true);
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchCumulativeValue() {
      this.$http
        .get(`/api/v1/transactions/cumulativevalue`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          this.cumulativeValue = response.data.data;
          this.cumulativeValueCategories = response.data.categories;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchSalesUplift() {
      this.$http
        .get(`/api/v1/transactions/uplift`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          // pick out average sales
          this.salesUplift = response.data.actual;
          this.salesUpliftCategories = response.data.categories;

          // sum total month by month uplift
          var total = this.salesUplift[0].data.reduce(
            (a, b) => Number(a) + Number(b),
            0
          );
          this.salesUpliftAbsolute = formatValue(total, this.locale, true);
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchProtectedRevenue() {
      this.$http
        .get(`/api/v1/transactions/protectedrevenue`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          this.protectedRevenue = formatValue(
            response.data.value,
            this.locale,
            true
          );
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchChurn() {
      this.$http
        .get(`/api/v1/customers/churn`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          this.churnCategories = response.data.categories;
          this.churnValue = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },

    submitPageChange() {
      document.dispatchEvent(
        new CustomEvent("adobeDataLayerEvent", {
          detail: {
            event: "content_view_change",
            page: {
              pageName: "EN:XX:VALUE CREATION DASHBOARD: " + document.URL,
              productSection: "private",
              productType: "VALUE CREATION DASHBOARD",
              userLanguage: "EN",
              usercountry: "XX",
              userType: this.$vueRole,
              userID: this.$kcUserId,
            },
          },
        })
      );
    },
  },
  created() {
    this.fetchData();
    this.timer = setInterval(this.fetchData, 7200000);
    this.submitPageChange();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.card-container {
  margin: auto;
  width: 90vw;
  min-height: 50vh;
}

.box-container.fixed {
  height: 235px;
}

.title-box {
  padding: 10px;
}
.title-main {
  font-size: 55px;
  font-weight: 100;
  text-shadow: 1px 1px 1px #bbb;
}
.title-footer {
  font-size: 25px;
  margin-bottom: 5px;
}

.value {
  font-size: 75px;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subheader {
  font-size: 18px;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subsubheader {
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
