<template>
  <div v-if="isLoading" class="plot-container text-center">
    <b-spinner label="Spinning" class="spinner"></b-spinner>
  </div>
  <div v-else>
    <div class="h2 mb-0">
      <div class="plot-title">
        {{ title }}
        <b-icon v-if="hasDescription" icon="info-circle" ref="info"></b-icon>
        <b-tooltip v-if="hasDescription" :target="() => $refs['info']">
          <div class="tooltip-internal">
            {{ description }}
          </div>
        </b-tooltip>
      </div>
      <div class="plot-subheader">{{ subheader }}</div>
    </div>
    <div class="legend">
      <div style="color: rgb(249, 174, 0)">Digital: {{ digitalDisplay }}%</div>
      <div style="color: rgb(0, 59, 126)">
        Non-Digital: {{ nonDigitalDisplay }}%
      </div>
    </div>
    <apexchart
      type="radialBar"
      :height="plotHeight"
      :options="chartOptions"
      :series="scaledSeries"
    />
  </div>
</template>

<script>
import formatValue from "@/components/Plots/formatValue";
import colorOptions from "@/components/Plots/colorOptions.js";
import plotSettings from "@/components/Plots/plotSettings";

export default {
  name: "ApexMultipleRadialBars",
  props: {
    title: String,
    series: Array,
    categories: Array,
    chartColors: { type: Array, default: colorOptions[0] },
    description: { type: String, default: "" },
    subheader: { type: String, default: "" },
    height: { type: Number, default: 300 },
  },
  computed: {
    plotHeight: function () {
      if (this.subheader === "") {
        return this.height + 12;
      } else {
        return this.height;
      }
    },
    isLoading: function () {
      return this.series.length === 0;
    },
    hasDescription: function () {
      return this.description != "";
    },
    factor() {
      const maxVal = Math.max(...this.series);
      return 100 / (Math.ceil(maxVal / 5) * 5);
    },
    scaledSeries() {
      return this.series.map(function (x) {
        return x * this.factor;
      }, this);
    },
    digitalDisplay() {
      return formatValue(this.scaledSeries[0] / this.factor);
    },
    nonDigitalDisplay() {
      return formatValue(this.scaledSeries[1] / this.factor);
    },
  },
  data() {
    return {
      timer: "",
      chartOptions: {
        colors: this.chartColors,
        title: plotSettings.title,
        chart: {
          fontFamily: plotSettings.fontFamily,
          zoom: plotSettings.zoom,
          toolbar: plotSettings.toolbar,
          animations: {
            enabled: true,
            speed: 1500,
            animateGradually: {
              delay: plotSettings.animationDelay,
            },
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              margin: 0,
              size: "66%",
              background: "transparent",
            },
            track: {
              background: "#eaeaea",
              margin: 2, // margin is in pixels
              startAngle: -135,
              endAngle: 135,
              strokeWidth: "100%",
              dropShadow: {
                enabled: true,
                top: 1,
                left: 0,
                blur: 1,
                opacity: 0.05,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
              total: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: "straight",
        },
        grid: {
          padding: {
            top: -25,
            bottom: 10,
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.legend {
  position: absolute;
  top: 115px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 19px;
  font-weight: 900;
  text-shadow: 1px 1px 1px #d6d6d6;
}
</style>
