const animationSpeed = 500;
const animationDelay = 50;

const plotSettings = {
  title: {
    text: "",
  },
  fontFamily: "Calibri, sans-serif",
  zoom: {
    enabled: false,
  },
  toolbar: {
    show: false,
  },
  animationsEase: {
    easing: "easein",
    speed: animationSpeed,
    animateGradually: {
      delay: animationDelay,
    },
  },
  animationsSimple: {
    speed: animationSpeed,
    animateGradually: {
      delay: animationDelay,
    },
  },
  tooltip: {
    enabled: false,
  },
  dropShadow: {
    enabled: true,
    opacity: 0.3,
    blur: 5,
    left: -7,
    top: 22,
  },
  stroke: {
    show: true,
    width: 0.5,
    colors: ["black"],
  },
  animationSpeed: animationSpeed,
  animationDelay: animationDelay,
};
export default plotSettings;
