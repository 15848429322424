<template>
  <div id="app">
    <nav class="main-nav">
      <div class="navbar-nav mr-auto" style="justify-content: center">
        <div class="logo">
          <img
            src="./assets/Logo_Dish_Inverted_RGB.png"
            style="height: 30px; padding-right: 20px"
            alt="HD logo"
          />
          <div class="d-none d-md-inline-block">
            Digital value creation dashboard | {{ currentRouteName }}
          </div>
        </div>
      </div>
      <div class="navbar-nav ml-auto">
        <img
          src="./assets/new-metro-logo.svg"
          style="height: 30px; padding-right: 20px"
          alt="Metro logo"
        />
      </div>
      <Burger></Burger>
    </nav>

    <Sidebar></Sidebar>

    <div class="body-container">
      <transition name="fade" mode="out-in">
        <router-view class="view one"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Burger from "@/components/Menu/Burger.vue";
import Sidebar from "@/components/Menu/Sidebar.vue";

export default {
  created() {
    const nonProdEnvironments = ["dev", "acc", "stg"];
    let adobeTrackingURL = "";
    if (nonProdEnvironments.includes(process.env.VUE_APP_ROUTER_HOST)) {
      adobeTrackingURL =
        "https://assets.adobedtm.com/de391e1809aa/03bc30fc79f6/launch-6a61eb03b8c6-development.min.js";
    } else if (process.env.VUE_APP_ROUTER_HOST === "prod") {
      adobeTrackingURL =
        "https://assets.adobedtm.com/de391e1809aa/03bc30fc79f6/launch-242379c7c236.min.js";
    } else {
      adobeTrackingURL = "https://localhost/script.js";
    }
    let adobeTU = document.createElement("script");
    adobeTU.setAttribute("src", adobeTrackingURL);
    adobeTU.toggleAttribute("async");
    document.head.appendChild(adobeTU);
  },
  name: "app",
  components: { Burger, Sidebar },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.logo {
  align-self: center;
  color: #fff;
  font-weight: 300;
  font-family: "calibri", sans-serif;
  font-size: 20px;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.3);
}
.main-nav {
  background-color: #002146;
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  border-bottom: 1px solid #f1ece3;
}
</style>
