const localeMapper = {
  at: "€",
  be: "€",
  cz: "Kč",
  pl: "zł",
  de: "€",
  fr: "€",
  es: "€",
  pt: "€",
  nl: "€",
  ro: "L",
  hr: "kn",
  hu: "Ft",
  it: "€",
  sk: "€",
  tr: "₺",
  ua: "₴",
  global: "€",
};
const decimalPlaces = 1;

function formatValue(
  value,
  locale,
  isCurrency = false,
  isPercentage = false,
  useShortFormat = false
) {
  var formatter = null;

  if (value === undefined || !value) {
    return value;
  }

  if (isPercentage) {
    // percentage values
    return value.toFixed(decimalPlaces).toString() + "%";
  } else if (isCurrency) {
    // short values
    formatter = new Intl.NumberFormat("en", {
      notation: "compact",
      compactDisplay: "short",
      maximumFractionDigits: decimalPlaces,
    });
    const currency = localeMapper[locale];
    return `${formatter.format(value)} ${currency}`;
  } else {
    // numerics
    if (value % 1 === 0) {
      // int
      if (useShortFormat) {
        formatter = new Intl.NumberFormat("en", {
          notation: "compact",
          compactDisplay: "short",
          maximumFractionDigits: decimalPlaces,
        });
      } else {
        formatter = new Intl.NumberFormat("en", {});
      }
      return formatter.format(value.toFixed(0));
    } else {
      // float
      return value.toFixed(decimalPlaces);
    }
  }
}

export default formatValue;
