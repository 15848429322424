// [0] short color (e.g. used in AreaChart)
// [1] standard color
// [2] reversed color
// [3] in bar label colors for [1]
// [4] in bar label colors for [2]
const colorOptions = [
  ["rgb(249, 174, 0)", "rgb(0, 59, 126)", "rgb(112, 174, 223)"],
  [
    "rgb(249, 174, 0)",
    "rgb(0, 59, 126)",
    "rgb(112, 174, 223)",
    "rgb(242, 142, 0)",
    "rgb(25, 97, 172)",
  ],
  [
    "rgb(0, 59, 126)",
    "rgb(249, 174, 0)",
    "rgb(242, 142, 0)",
    "rgb(112, 174, 223)",
    "rgb(25, 97, 172)",
  ],
  ["rgb(25, 97, 172)", "rgb(253, 203, 83)"],
  ["rgb(253, 203, 83)", "rgb(25, 97, 172)"],
];

export default colorOptions;
