<template>
  <div class="value_box">
    <div class="header"><slot name="main"></slot></div>
    <hr class="hr" />
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValueBox",
  props: ["footer"],
};
</script>

<style scoped>
.value_box {
  background-color: #002146;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.header {
  margin-left: 5px;
}

.hr {
  margin-bottom: 0;
  margin-top: 0;
  border-color: rgb(230, 230, 230);
}

.footer {
  margin-bottom: 0;
  text-align: right;
}
</style>
