<template>
  <div>
    <Template country="Poland" locale="pl" />
  </div>
</template>

<script>
import Template from "@/views/CountryViews/Template.vue";

export default {
  name: "poland-view",
  components: {
    Template,
  },
};
</script>
