import Vue from "vue";
import Keycloak from "keycloak-js";

let router_url = "";
if (process.env.VUE_APP_ROUTER_HOST === "dev") {
  router_url = "https://dev.sso.app.hd.digital/auth/";
} else if (process.env.VUE_APP_ROUTER_HOST === "acc") {
  router_url = "https://acc.sso.app.hd.digital/auth/";
} else if (process.env.VUE_APP_ROUTER_HOST === "stg") {
  router_url = "https://stage.sso.app.hd.digital/auth/";
} else if (process.env.VUE_APP_ROUTER_HOST === "prod") {
  router_url = "https://sso.dish.co/auth/";
} else {
  router_url = "https://sso.dish.co/auth/";
}

//url must be keycloak base server URL using the auth directory
//Realm and client ID set in keycloak admin dashboard
const options = {
  url: router_url,
  realm: "HDAdmin",
  clientId: "VCDB",
};

const _keycloak = Keycloak(options);

const authenticator = {
  install(Vue) {
    Vue.$keycloak = _keycloak;
  },
};

authenticator.install = (Vue) => {
  Vue.$keycloak = _keycloak;
  Vue.$roles = [];
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak;
      },
    },
  });
};

Vue.use(authenticator);

export default authenticator;
