<template>
  <div v-if="isLoading" class="plot-container text-center">
    <b-spinner label="Spinning" class="spinner"></b-spinner>
  </div>
  <div v-else>
    <div class="h2 mb-0">
      <div class="plot-title">
        {{ title }}
        <b-icon v-if="hasDescription" icon="info-circle" ref="info"></b-icon>
        <b-tooltip v-if="hasDescription" :target="() => $refs['info']">
          <div class="tooltip-internal">
            {{ description }}
          </div>
        </b-tooltip>
      </div>
      <div class="plot-subheader">{{ subheader }}</div>
    </div>
    <apexchart
      type="bar"
      :height="plotHeight"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import formatValue from "@/components/Plots/formatValue";
import colorOptions from "@/components/Plots/colorOptions.js";
import plotSettings from "@/components/Plots/plotSettings";

export default {
  name: "ApexBarChartsGrouped",
  props: {
    title: String,
    series: Array,
    categories: Array,
    distributed: { type: Boolean, default: true },
    stacked: { type: Boolean, default: false },
    isCurrency: { type: Boolean, default: false },
    locale: { type: String },
    isPercentage: { type: Boolean, default: false },
    enableDataLabels: { type: Boolean, default: false },
    height: { type: Number, default: 210 },
    description: { type: String, default: "" },
    subheader: { type: String, default: "" },
    chartColors: { type: Array, default: colorOptions[1] },
    labelColors: { type: Array, default: colorOptions[3] },
  },
  computed: {
    plotHeight: function () {
      if (this.subheader === "") {
        return this.height + 12;
      } else {
        return this.height;
      }
    },
    isLoading: function () {
      return this.series.length === 0;
    },
    hasDescription: function () {
      return this.description != "";
    },
    showLegend: function () {
      // show if more than one category
      return this.series.length > 1;
    },
    chartOptions: function () {
      return {
        colors: this.chartColors,
        title: plotSettings.title,
        chart: {
          fontFamily: plotSettings.fontFamily,
          zoom: plotSettings.zoom,
          toolbar: plotSettings.toolbar,
          animations: plotSettings.animationsEase,
          stacked: this.stacked,
        },
        legend: {
          show: this.showLegend,
          labels: {
            colors: "black",
          },
        },
        plotOptions: {
          bar: {
            distributed: this.distributed,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: this.enableDataLabels && !this.stacked,
          textAnchor: "end",
          offsetX: -7 + !this.distributed * 35, //for some reason, the setting is not respected equally cust. per bundle/churn
          style: {
            fontSize: "11px",
            fontWeight: 1000,
            colors: this.labelColors,
          },
          formatter: (value) => {
            return formatValue(
              value,
              this.locale,
              this.isCurrency,
              this.isPercentage,
              true
            );
          },
        },
        stroke: plotSettings.stroke,
        xaxis: {
          categories: this.categories,
          tickAmount: 4,
          axisBorder: {
            show: true,
            color: "black",
          },
          axisTicks: {
            show: !this.enableDataLabels,
          },
          labels: {
            show: !this.enableDataLabels,
            style: {
              colors: "black",
            },
            formatter: (value) => {
              return formatValue(
                value,
                this.locale,
                this.isCurrency,
                this.isPercentage,
                true
              );
            },
          },
        },
        yaxis: {
          labels: {
            offsetX: 7,
            style: {
              colors: "black",
              fontWeight: 600,
            },
          },
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return formatValue(
                value,
                this.locale,
                this.isCurrency,
                this.isPercentage
              );
            },
          },
        },
        grid: {
          padding: {
            top: -30,
            bottom: -10,
            left: 0,
          },
        },
      };
    },
  },
};
</script>
