<template>
  <div>
    <Template country="France" locale="fr" />
  </div>
</template>

<script>
import Template from "@/views/CountryViews/Template.vue";

export default {
  name: "france-view",
  components: {
    Template,
  },
};
</script>
