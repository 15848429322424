<template>
  <div>
    <Template country="Ukraine" locale="ua" />
  </div>
</template>

<script>
import Template from "@/views/CountryViews/Template.vue";

export default {
  name: "ukraine-view",
  components: {
    Template,
  },
};
</script>
