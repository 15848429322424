<template>
  <div>
    <Template country="Netherlands" locale="nl" />
  </div>
</template>

<script>
import Template from "@/views/CountryViews/Template.vue";

export default {
  name: "netherlands-view",
  components: {
    Template,
  },
};
</script>
