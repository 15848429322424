<template>
  <div>
    <div v-for="(contentText, index) in displayText" :key="index">
      <b-form-row>
        <b-col cols="12">
          <div
            class="box-container flex; vertical-align: middle; border: solid; border-width: 0.1em; border-color: black;"
          >
            <div style="padding: 0.1em 2em">
              <div
                style="
                  padding: 1em;
                  text-align: left;
                  color: black;
                  font-weight: bold;
                "
              >
                {{ contentText }}
              </div>
            </div>
          </div>
        </b-col>
      </b-form-row>
    </div>
    <!-- Top-level row -->
    <b-form-row>
      <!-- Plots -->
      <b-col cols="12" sm="6" lg="4">
        <div class="box-container fixed">
          <HorizontalBarChart
            title="Churn comparison [%]"
            :series="churnValue"
            :categories="churnCategories"
            :isPercentage="true"
            :locale="locale"
            :enableDataLabels="true"
            description="Percentage customer outflow rates for digital vs. non-digital METRO customers - customers that have not made a purchase in the last 12 months are considered as churned. Limited to METRO's HoReCa customers created more than 12 months ago. Additionaly, digital customers were active HD customers one year ago."
            subheader="Until end of last month"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <div class="box-container fixed">
          <BarChart
            title="Digital sales uplift [%]"
            :series="salesUplift"
            :categories="salesUpliftCategories"
            :chartColors="chartColorsHM"
            :isPercentage="true"
            :shortAxisLabels="false"
            :locale="locale"
            description="Quarterly sales uplift per METRO customer: Incremental METRO sales value for new HD customers (being in 12 months period after moment of digital conversion) vs. non-digital customers for the last 4 fiscal quarters (FQ). Limited to active HD and active HoReCa customers being METRO customers for more than two years."
            subheader="Until end of last FQ, quarterly level"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <div class="box-container fixed">
          <Gauge
            title="Own brand share [%]"
            :series="obProportion"
            :categories="obCategories"
            description="Percentage of METRO revenue generated via purchases of METRO own brands for digital/non-digital customers, based on last 12 months. Limited to active HoReCa (and active HD) customers being METRO customers (and digitized) for more than one year."
            subheader="Until end of last month"
          />
        </div>
      </b-col>
      <!-- Second Row -->
      <b-col cols="12" sm="6" lg="4">
        <div class="box-container fixed">
          <BarChart
            :title="`Average basket value [${
              this.currencyMapper[this.locale]
            }]`"
            :series="averageSalesValue"
            :categories="averageSalesCategories"
            :chartColors="chartColorsMH"
            :distributed="false"
            :isCurrency="true"
            :locale="locale"
            :slimline="true"
            description="Average quarterly basket value for digital/non-digital METRO customers for the last 4 fiscal quarters (FQ). Limited to active HoReCa (and active HD) customers being METRO customers (and digitized) for more than one year."
            subheader="Until end of last FQ, quarterly average"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <div class="box-container fixed">
          <BarChart
            title="Average purchasing frequency"
            :series="purchasingFrequency"
            :categories="purchasingCategories"
            :chartColors="chartColorsMH"
            :distributed="false"
            :enableDataLabels="true"
            description="Average quarterly number of days of purchases for digital/non-digital METRO customers for the last 4 fiscal quarters (FQ). Limited to active HoReCa (and active HD) customers being METRO customers (and digitized) for more than one year."
            subheader="Until end of last FQ, quarterly average"
          />
        </div>
      </b-col>

      <b-col cols="12" sm="6" lg="4">
        <div class="box-container fixed">
          <BarChart
            :title="`Average sales value [${this.currencyMapper[this.locale]}]`"
            :series="cumulativeValue"
            :categories="cumulativeValueCategories"
            :chartColors="chartColorsMH"
            :distributed="false"
            :isCurrency="true"
            :locale="locale"
            description="Average basket value x average number of visits for digital/non-digital METRO customers. Calculation is on quarterly basis. Limited to active HoReCa (and active HD) customers being METRO customers (and digitized) for more than one year."
            subheader="Until end of last FQ, quarterly average"
          />
        </div>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <div class="box-container flex; vertical-align: middle">
          <div style="padding: 0.1em 2em">
            <div
              style="
                padding: 1em;
                text-align: left;
                color: black;
                font-size: 11pt;
                font-weight: normal;
              "
            >
              <p>
                Global statistics are presented in aggregated form, either per
                fiscal quarter or over the last 12 months. <br />The last 4
                complete quarters are shown. Each quarter is calculated and
                stored (i.e. fixed) at the end of that quarter. <br />The annual
                values are updated monthly with the currently valid calculation
                basis.
              </p>
              <p>
                Countries included: <br />Belgium (until fiscal year 2022),
                Croatia, Czechia, Germany, France, Hungary, Italy, the
                Netherlands, Poland, Portugal, Romania, Slovakia and Spain.
              </p>
            </div>
          </div>
        </div>
      </b-col>
    </b-form-row>
    <!-- Financial Year View -->
  </div>
</template>

<script>
import Gauge from "@/components/Plots/Gauge.vue";
import BarChart from "@/components/Plots/BarChart.vue";
import HorizontalBarChart from "@/components/Plots/HorizontalBarChart.vue";
import colorOptions from "@/components/Plots/colorOptions.js";

export default {
  name: "template-country-view",
  props: { country: { type: String }, locale: { type: String } },
  components: {
    Gauge,
    BarChart,
    HorizontalBarChart,
  },
  data() {
    return {
      //
      currencyMapper: {
        global: "€",
      },
      displayText: [],
      //
      averageSalesCategories: [],
      averageSalesValue: [],
      //
      purchasingFrequency: [],
      purchasingCategories: [],
      //
      cumulativeValue: [],
      cumulativeValueCategories: [],
      //
      salesUpliftCategories: [],
      salesUplift: [],
      //
      churnCategories: [],
      churnValue: [],
      //
      obProportion: [],
      obCategories: [],
      //
      timer: "",
      chartColorsHM: [],
      chartColorsMH: [],
    };
  },
  methods: {
    fetchData() {
      this.fetchMessages();
      this.fetchSalesValue();
      this.fetchCumulativeValue();
      this.fetchSalesUplift();
      this.fetchChurn();
      this.fetchObProportion();
      this.setChartColors();
      this.locale = "global";
      this.country = "global";
    },
    setChartColors() {
      this.chartColorsMH = colorOptions[2];
      this.chartColorsHM = colorOptions[1];
    },
    deltaInCategories(values, categories, divisor, precision, label) {
      const round = (number, digits) =>
        Number(Math.round(number + "e" + digits) + "e-" + digits);

      let i;
      const output = [];
      for (i = 0; i < categories.length; i++) {
        output.push([
          [categories[i]],
          [
            "Δ " +
              round(
                (parseFloat(values[1].data[i]) -
                  parseFloat(values[0].data[i])) /
                  divisor,
                precision
              ).toString() +
              label +
              " | ",
          ] +
            round(
              ((parseFloat(values[1].data[i]) - parseFloat(values[0].data[i])) *
                100) /
                parseFloat(values[1].data[i]),
              precision
            ).toString() +
            "%",
        ]);
      }
      return output;
    },
    fetchMessages() {
      this.$http
        .get(`/api/v1/information/messages`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          this.displayText = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchSalesValue() {
      this.$http
        .get(`/api/v1/transactions/sales`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          this.averageSalesValue = response.data.basket.data;
          this.averageSalesCategories = this.deltaInCategories(
            this.averageSalesValue,
            response.data.basket.categories,
            1,
            1,
            "€"
          );

          this.purchasingFrequency = response.data.visits.data;
          this.purchasingCategories = this.deltaInCategories(
            this.purchasingFrequency,
            response.data.visits.categories,
            1,
            1,
            ""
          );
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },

    fetchCumulativeValue() {
      this.$http
        .get(`/api/v1/transactions/cumulativevalue`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          this.cumulativeValue = response.data.data;
          this.cumulativeValueCategories = this.deltaInCategories(
            this.cumulativeValue,
            response.data.categories,
            1000,
            1,
            "K €"
          );
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchSalesUplift() {
      const excludedCountries = [
        "Austria",
        "Romania",
        "Belgium",
        "Slovakia",
        "Croatia",
      ];
      const excludedOnEnv = ["prod", "acc", "stg"];
      if (
        excludedOnEnv.includes(process.env.VUE_APP_ROUTER_HOST) &&
        excludedCountries.includes(this.country)
      ) {
        this.salesUplift = {};
        this.salesUpliftCategories = {};
      } else if (process.env.VUE_APP_ROUTER_HOST === "dev") {
        this.$http
          .get(`/api/v1/transactions/uplift_psm_dev`, {
            params: {
              country: "global",
            },
          })
          .then((response) => {
            this.salesUplift = response.data.percentage;
            this.salesUpliftCategories = response.data.categories;
          })
          .catch(() => {
            //gracefully catch unsuccessful fetch
          });
      } else {
        this.$http
          .get(`/api/v1/transactions/uplift_psm_prod`, {
            params: {
              country: "global",
            },
          })
          .then((response) => {
            this.salesUplift = response.data.percentage;
            this.salesUpliftCategories = response.data.categories;
          })
          .catch(() => {
            //gracefully catch unsuccessful fetch
          });
      }
    },
    fetchChurn() {
      this.$http
        .get(`/api/v1/customers/churn`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          this.churnCategories = response.data.categories;
          this.churnValue = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },
    fetchObProportion() {
      this.$http
        .get(`/api/v1/transactions/ownbrand`, {
          params: {
            country: "global",
          },
        })
        .then((response) => {
          // JSON responses are automatically parsed.
          this.obCategories = response.data.categories;
          this.obProportion = response.data.data;
        })
        .catch(() => {
          //gracefully catch unsuccessful fetch
        });
    },

    submitPageChange() {
      document.dispatchEvent(
        new CustomEvent("adobeDataLayerEvent", {
          detail: {
            event: "content_view_change",
            page: {
              pageName: "EN:XX:VALUE CREATION DASHBOARD: " + document.URL,
              productSection: "private",
              productType: "VALUE CREATION DASHBOARD",
              userLanguage: "EN",
              usercountry: "XX",
              userType: this.$vueRole,
              userID: this.$kcUserId,
            },
          },
        })
      );
    },
  },
  created() {
    this.fetchData();
    this.timer = setInterval(this.fetchData, 7200000);
    this.submitPageChange();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
h2 {
  font-size: 37px;
  margin-bottom: 5px;
}
</style>
